  <template>
  <v-container grid-list-md justify-space-around="True" style="height: 100%;">
      <v-layout row wrap>
          <!--Budget Settings-->
          <v-flex xs12 sm12 md6>
              <mmwoptions></mmwoptions>
          </v-flex>

          <!--Currency Section-->
          <v-flex xs12 sm12 md6>
              <currency></currency>
          </v-flex>

          <!--Period Section-->
          <v-flex xs12 sm12 md6>
              <period></period>
          </v-flex>


      </v-layout>
  </v-container>

</template>

<script>

  export default {
    components: {
          currency: () => import('./currencySection.vue'),
          mmwoptions: () => import('./MWWOptions.vue'),
          period: () => import('./selectPeriod.vue')

    },
  }
</script>

<style>
  .img-circle {
    border-radius: 50%;
  }

  .blockDisplay {
    display: block !important;
  }

  .ProfilePic {
    margin: 0 auto;
    max-width: 100px;
    max-height: 100px;
  }

  .toolbar {
    background-color: rgba(255, 255, 255, 0.00) !important;
  }

  .v-list__tile__sub-title {
    display: block !important;
  }

  .head_label{
    font-size: 16px;
  }
</style>
